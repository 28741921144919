import Notify from 'quasar/src/plugins/Notify.js';;

const message = {
  success(msg) {
    Notify.create({
      icon: "insert_emoticon",
      message: `${msg} `,
      color: "green",
      position: "top",
      timeout: 1000
    });
  },
  error(msg) {
    Notify.create({
      icon: "announcement",
      message: msg,
      color: "red",
      position: "top",
      timeout: 1000
    });
  },
  warning(msg) {
    Notify.create({
      icon: "warning",
      message: msg,
      color: "warning",
      position: "top",
      timeout: 1000
    });
  },
  info(msg) {
    Notify.create({
      icon: "info",
      message: msg,
      color: "info",
      position: "top",
      timeout: 1000
    });
  },
};

export default message;
