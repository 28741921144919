import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import Login from "./login.vue";
import SetNewPassword from "./setNewPassword.vue";
import UpdatePassword from "./updatePassword.vue";

import message from '@/utils/message';

// 登录
Login.install = function () {
  // 创建空的div节点，添加到body中
  const mountNode = document.createElement("div");
  document.body.appendChild(mountNode);

  //将组件挂载到div节点中
  const app = createApp(Login, {
    remove() {
      app.unmount(mountNode); // 使用完后进行销毁
      document.body.removeChild(mountNode);
    }
  });
  app.use(Quasar);
  app.config.globalProperties.$message = message;
  app.config.globalProperties.$q.SetNewPassword = SetNewPassword.install
  const instance = app.mount(mountNode);

  return instance.open();
};

// 设置新密码（密码复杂度不符合规则，强制）
SetNewPassword.install = function (data) {
  // 创建空的div节点，添加到body中
  const mountNode = document.createElement("div");
  document.body.appendChild(mountNode);

  //将组件挂载到div节点中
  const app = createApp(SetNewPassword, {
    remove() {
      app.unmount(mountNode); // 使用完后进行销毁
      document.body.removeChild(mountNode);
    }
  });
  app.use(Quasar);
  app.config.globalProperties.$message = message;
  const instance = app.mount(mountNode);

  return instance.open(data);
};

// 更新密码（主动）
UpdatePassword.install = function (data) {
  // 创建空的div节点，添加到body中
  const mountNode = document.createElement("div");
  document.body.appendChild(mountNode);

  // 将组件挂载到div节点中
  const app = createApp(UpdatePassword, {
    remove() {
      app.unmount(mountNode); // 使用完后进行销毁
      document.body.removeChild(mountNode);
    }
  });
  app.use(Quasar);
  app.config.globalProperties.$message = message;
  const instance = app.mount(mountNode);

  return instance.open(data);
};

export { Login, SetNewPassword, UpdatePassword };