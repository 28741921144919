import router from "@/router";

export function pageJump(location) {
  //console.log(location, location);
  /* if (location.name == "login") {
    store.commit("LOGOUT");
  } */
  router.push(location);
}

export function pageOpenBlank(routerParams, childParams) {
  let url = router.resolve(routerParams);
  window.open(window.location.origin + url.href + (childParams ? encodeURIComponent(childParams) : ""), "_blank");
}
