import _config from "@/config/index";

// 获取文件路径
export const filePath = (fileKey) => {
  if (fileKey) {
    if (
      fileKey.startsWith("http:") ||
      fileKey.startsWith("https:") ||
      fileKey.startsWith("data:") ||
      fileKey.startsWith("blob")
    ) {
      return fileKey;
    }
    return `${_config.fileHost}/bfileinfo/${fileKey}`;
  }
};

// 获取文档文件路径
export const docFilePath = (fileKey) => {
  if (fileKey) {
    if (
      fileKey.startsWith("http:") ||
      fileKey.startsWith("https:") ||
      fileKey.startsWith("data:") ||
      fileKey.startsWith("blob")
    ) {
      return fileKey;
    } else if (fileKey.endsWith(".pdf")) {
      return `${_config.fileHost}/bfileinfo/document/${fileKey}`;
    } else {
      return `${_config.fileHost}/bfileinfo/pdf/${fileKey}`;
    }
  }
}

export const fileFormat = (fileKey) => {
  return filePath(fileKey)
};
// 获取缩略图路径
export const thumbnailFormat = (size, fileKey) => {
  if (fileKey) {
    return filePath(`thumbnail/${size}/${fileKey}`);
  } else {
    return "../img/banner.jpg";
  }
};

export const docFileFormat = (fileKey, type) => {
  if (type === "pdfpath") {
    return docFilePath(fileKey);
  }
}

// 获取文档路径
export const documentFormat = (fileKey) => {
  return filePath(`document/${fileKey}`);
};
