const routes = [
  {
    path: "/",
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/pages/login/index.vue"),
      },
      {
        path: "/loginbypwd",
        name: "loginbypwd",
        component: () => import("@/pages/login/loginbypwd.vue"),
      },
      {
        path: "",
        redirect: "/" + window.modulemaps.headerModules[0],
        component: () => import("@/layouts/ViewLayout.vue"),
        children: [
          // {
          //   path: "/home/:page*",
          //   name: "home",
          //   component: () => import("@/pages/Home.vue")
          // },
          {
            path: "/home",
            name: "home",
            component: () => import("@/pages/Home/index.vue"),
          },
          // 测试页面
          {
            path: "/testPage",
            name: "testPage",
            component: () => import("@/pages/TestPage.vue"),
          },
          // 实训中心
          {
            path: "/intelligentLab/:page*",
            name: "intelligentLab",
            component: () => import("@/pages/LibraryApp/index.vue"),
          },
          // 课程中心
          {
            path: "/libcourseapp/:page*",
            name: "libcourseapp",
            component: () => import("@/pages/LibCourseApp/index.vue"),
          },
          // 个人中心（管理中心）
          {
            path: "/manageCenter/:page*",
            name: "manageCenter",
            component: () => import("@/pages/ManageCenter/index.vue"),
          },
          // 系统管理（基础数据管理）
          {
            path: "/systemManager/:page*",
            name: "systemManager",
            component: () => import("@/pages/SystemManager/index.vue"),
          },
          // 文件管理
          {
            path: "/fileManage/:page*",
            name: "fileManage",
            component: () => import("@/pages/FileManage/index.vue"),
          },
          // 资源中心
          {
            path: "/playerResourceCenter/:page*",
            name: "playerResourceCenter",
            component: () => import("@/pages/PlayerResourceCenter/index.vue"),
          },
          // 资料中心
          {
            path: "/resourceCenter/:page*",
            name: "resourceCenter",
            component: () => import("@/pages/ResourceCenter/index.vue"),
          },
          // 实训管理
          {
            path: "/librarysys/:page*",
            name: "librarysys",
            component: () => import("@/pages/LibrarySys/index.vue"),
          },
          // 课程管理
          {
            path: "/libcoursesys/:page*",
            name: "libcoursesys",
            component: () => import("@/pages/LibCourseSys/index.vue"),
          },
          // 考核管理
          {
            path: "/examsys/:page*",
            name: "examsys",
            component: () => import("@/pages/ExamSys/index.vue"),
          },
          // 数据统计
          {
            path: "/statistics/:page*",
            name: "statistics",
            component: () => import("@/pages/Statistics/index.vue"),
          },
          // 模拟训练
          {
            path: "/simulationsys/:page*",
            name: "simulationsys",
            component: () => import("@/pages/SimulationSys/index.vue"),
          },
          // 模拟训练中心
          {
            path: "/simulationapp/:page*",
            name: "simulationapp",
            component: () => import("@/pages/SimulationApp/index.vue"),
          },
          // 比武管理
          {
            path: "/competesys/:page*",
            name: "competesys",
            component: () => import("@/pages/CompeteSys/index.vue"),
          },
          // 比武中心
          {
            path: "/competeapp/:page*",
            name: "competeapp",
            component: () => import("@/pages/CompeteApp/index.vue"),
          },
          // 教学计划
          {
            path: "/plansys/:page*",
            name: "plansys",
            component: () => import("@/pages/PlanSys/index.vue"),
          },
          // 教学计划中心
          {
            path: "/planapp/:page*",
            name: "planapp",
            component: () => import("@/pages/PlanApp/index.vue"),
          },
        ],
      },
      // {
      //   path: "/plugin",
      //   name: "plugin",
      //   component: () => import("@/pages/plugin/index.vue"),
      // },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  // {
  //   path: "*",
  //   component: () => import("@/pages/Error404.vue"),
  // },
];

export default routes;
