import { useronline } from "@/api/user";

const actions = {
  onlineRecord({ commit, dispatch }) {
    let timer = setTimeout(() => {
      let onlineKey = sessionStorage.getItem("onlineKey");
      // 更新用户在线数据
      useronline({ onlineKey }).then(() => {
        dispatch("onlineRecord");
      }).catch((error) => {});
    }, 20 * 1000);

    commit("set_onlineTimer", timer);
  }
}

export default actions
