<template>
  <q-dialog id="login" v-model="show" persistent>
    <div class="content">
      <div class="header flex justify-center items-center">
        <div class="title">请登录</div>
        <div class="close" @click="cancelLogin">
          <div class="closeIcon"></div>
        </div>
      </div>
      <div class="main">
        <div class="login-box wx-login-box">
          <div class="login-box-login m-4">
            <div class="login-title w-100 mb-2">
              微信扫码登录
              <div class="" style="width: 2rem; height: 3px; background: #2b8cfe"></div>
            </div>
            <div class="login-title-tips ">
              微信扫码，关注公众号 后即可登录/注册
            </div>

            <div class="wx-login mt-2">
              <div id="bsti-wx-login-box" style="text-align: center;">
                <img class="bsti-wxqr" v-if="wxticketPic" style="width: 15rem; height: 15rem;" :src="wxticketPic" />
                <div>{{ wxqrloginTips }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main" style="display: none;">
        <q-form @submit="onSubmit">
          <q-input bg-color="myWathet" outlined dense v-model="formData.userName"
            :rules="[(val) => (val && val.length > 0) || '请输入账号']">
            <template v-slot:before>
              <div class="text-dark flex items-center text-bold input-text justify-end">
                账号 ：
              </div>
            </template>
          </q-input>
          <q-input bg-color="myWathet" outlined :type="iconState.password == 'visibility' ? 'password' : 'text'" dense
            v-model="formData.password" :rules="[
              (val) => (val && val.length > 0) || '请输入密码'
            ]">
            <template v-slot:before>
              <div class="text-dark flex items-center text-bold input-text justify-end">
                密码 ：
              </div>
            </template>
            <template v-slot:append>
              <q-btn round dense flat :icon="iconState.password" @mousedown="iconState.password = 'visibility_off'"
                @mouseup="iconState.password = 'visibility'"></q-btn>
            </template>
          </q-input>
          <div class="btn-box">
            <q-btn unelevated rounded class="submitBtn" type="submit">
              <span>登录</span>
            </q-btn>
          </div>
        </q-form>
        <div class="regist" @click="goRegist">点击此处注册</div>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { formLogin } from '@/api/login';
import { personalInfo } from '@/api/personal';
import { useronline } from "@/api/user";
import { pageOpenBlank } from "@/utils/pageJump";
import { loginqrticket, checkqrLoginResult, loginbywxcode } from '@/api/mpwechatauthor';
export default {
  data() {
    return {
      show: false,
      formData: {
        userName: "",
        password: ""
      },
      iconState: {
        password: "visibility"
      },
      wxticket: '',
      wxticketPic: '',
      wxticketloginInterval: null,
      wxqrloginTips: '',
      App_id: 'wxf199f4d4b19ce5e1'
    };
  },

  props: {
    remove: {
      type: Function,
      default: null
    }
  },

  watch: {
    show(n) {
      if (!n) {
        if (this.remove) this.remove();
      }
    }
  },

  mounted() { },

  methods: {
    open() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.getloginqrticket()
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getloginqrticket() {
      // wxqrloginTips.value = '';
      const result = await loginqrticket(this.App_id);
      this.wxticket = result;
      this.wxticketPic = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + encodeURI(result)
      let num = 1;
      this.wxticketloginInterval = setInterval(async () => {
        num++;
        const result = await checkqrLoginResult(this.wxticket)
        if (result && result.accessToken) {
          this.$message.success("登录成功");
          sessionStorage.setItem('access_token', result.accessToken)
          Promise.all([personalInfo(), useronline({ onlineKey: "" })]).then((resp) => {
            sessionStorage.setItem("personal", JSON.stringify(resp[0]));
            sessionStorage.setItem("rights", JSON.stringify(resp[0].rights));

            this.show = false;
            if (this.resolve) this.resolve();
          }).catch((error) => {
            if (this.reject) this.reject();
          });
          clearInterval(this.wxticketloginInterval);
        } else if (num === 60) {
          clearInterval(this.wxticketloginInterval);
          this.wxqrloginTips = '微信二维码已过期，请重新扫码登录'
          this.getloginqrticket();
        }
      }, 1000);
    },

    onSubmit() {
      formLogin(this.formData).then((res) => {
        if (res.isChangePassword) {
          this.$q.SetNewPassword({
            oldPassword: this.formData.password,
            changePasswordCode: res.changePasswordCode,
          }).then(res => {
            this.formData.password = res;
            this.onSubmit();
          });
        } else {
          this.$message.success("登录成功");
          sessionStorage.setItem("access_token", res.accessToken);
         // sessionStorage.setItem("rights", res.rights);

          Promise.all([personalInfo(), useronline({ onlineKey: "" })]).then((resp) => {
            sessionStorage.setItem("personal", JSON.stringify(resp[0]));
            sessionStorage.setItem("rights", JSON.stringify(resp[0].rights));
            // 设置用户在线标志Key
            sessionStorage.setItem("onlineKey", resp[1]);

            this.show = false;
            if (this.resolve) this.resolve();
          }).catch((error) => {
            if (this.reject) this.reject();
          });

          // // 设置用户在线标志Key
          // useronline({ onlineKey: "" }).then((resp) => {
          //   sessionStorage.setItem("onlineKey", resp);
          // }).catch((error) => {});
        }
      }).catch((error) => {
        if (this.reject) this.reject();
      });
    },
    goRegist() {
      pageOpenBlank(
        {
          name: "login",
          query: { initialState: "regist" }
        }
      );
    },
    cancelLogin() {
      this.show = false;
      if (this.reject) this.reject();
    }
  }
};
</script>

<style lang="scss" scoped>
#login {
  .content {
    width: 500px;
    max-width: none;
    box-shadow: none;
    overflow: hidden;

    .header {
      width: 100%;
      height: 90px;
      position: relative;
      border-radius: 60% 60% 0 0;
      background-image: linear-gradient(90deg, #7d1edc 0%, #12c2e9 100%);

      .title {
        font-size: 36px;
        color: #ffffff;
      }

      .close {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        position: absolute;
        right: 27px;
        bottom: 15px;
        cursor: pointer;
        border: 2px solid white;
        transition: all 0.5s;

        &:hover {
          transform: rotate(90deg) scale(1.08);
        }

        .closeIcon {

          &::after,
          &::before {
            content: "";
            position: absolute;
            left: 15px;
            height: 20px;
            width: 1.5px;
            top: 6px;
            background-color: #fff;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 138px);
      max-height: 360px;
      border-radius: 0 0 20px 20px;
      background: #fff;
      padding: 20px;
      overflow: auto;

      .q-form {

        .input-text {
          width: 100px;
          height: 100%;
          font-size: 15px;
        }

        .q-field--with-bottom {
          padding-bottom: 32px;
        }

        .q-input {
          margin-top: 10px;
          width: 360px;
        }

        .btn-box {
          margin-top: 10px;

          .submitBtn {
            margin: 0 auto;
            display: block;
            // background-image: linear-gradient(90deg, #12c2e9 0%, #7d1edc 100%);
            font-size: 20px !important;
            color: #fff;
            padding: 3px 60px;
            min-height: 0px;
            background: linear-gradient(to right,
                #12c2e9 0%,
                #7d1edc 50%,
                #12c2e9 100%);
            background-size: 200% auto;
            transition: 0.5s;

            &:hover {
              background-position: right center;
            }
          }
        }
      }

      .regist {
        color: #296dd6;
        flex-shrink: 0;
        margin: 10px 0 0;
        cursor: pointer;
      }
    }
  }
}

:deep(.bg-myWathet) {
  background: #e5f2ff !important;
}

.login-box {
  right: 200px;
  width: 450px;
  top: 100px;

  opacity: 1;
  border-radius: 10px;
}

.login-title {
  font-weight: bold;
  color: #000000;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px;
}

.login-title-tips {
  font-weight: 400;
  color: #2B8CFE;
  font-size: 0.8rem;
  padding: 0 10px;
}
</style>
