// import Vue from 'vue'
import { hasRight, checkRight } from '@/utils/rights'

// export default ({ app }) => {
//   Vue.directive("rights", {
//     bind(el, binding) {
//       if (checkRight(binding.value)) {
//       } else {
//         el.style.display = "none";
//         // el.addClass("hide");
//       }
//     },
//   });
//   Vue.prototype.$hasRight = hasRight;
//   Vue.prototype.$checkRight = checkRight;
// };

export default {
  install: (app) => {
    app.config.globalProperties.$hasRight = hasRight
    app.config.globalProperties.$checkRight = checkRight
  },
}
