<template>
  <q-dialog id="update-password" v-model="show" persistent>
    <div class="content">
      <div class="header flex justify-center items-center">
        <div class="title">修改密码</div>
        <div class="close" @click="show = false">
          <div class="closeIcon"></div>
        </div>
      </div>
      <div class="main">
        <q-form @submit="onSubmit">
          <q-input
            bg-color="myWathet"
            outlined
            :type="iconState.oldPassword == 'visibility' ? 'password' : 'text'"
            dense
            v-model="formData.oldPassword"
            :rules="[(val) => (val && val.length > 0) || '请输入旧密码']"
          >
            <template v-slot:before>
              <div
                class="text-dark flex items-center text-bold input-text justify-end"
              >
                旧密码：
              </div>
            </template>
            <template v-slot:append>
              <q-btn
                round
                dense
                flat
                :icon="iconState.oldPassword"
                @mousedown="iconState.oldPassword = 'visibility_off'"
                @mouseup="iconState.oldPassword = 'visibility'"
              ></q-btn>
            </template>
          </q-input>
          <q-input
            bg-color="myWathet"
            outlined
            :type="iconState.newPassword == 'visibility' ? 'password' : 'text'"
            dense
            v-model="formData.newPassword"
            hint="密码至少8个字符，至少1个大写字母，1个小写字母和1个数字"
            :rules="[
              (val) => (val && val.length > 0) || '请输入新密码',
              (val) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/.test(val) ||
                '密码至少8个字符，至少1个大写字母，1个小写字母和1个数字',
            ]"
          >
            <template v-slot:before>
              <div class="text-dark flex items-center text-bold input-text justify-end">
                新密码：
              </div>
            </template>
            <template v-slot:append>
              <q-btn
                round
                dense
                flat
                :icon="iconState.newPassword"
                @mousedown="iconState.newPassword = 'visibility_off'"
                @mouseup="iconState.newPassword = 'visibility'"
              ></q-btn>
            </template>
          </q-input>
          <q-input
            bg-color="myWathet"
            outlined
            :type="
              iconState.confirmNewPassword == 'visibility' ? 'password' : 'text'
            "
            dense
            v-model="formData.confirmNewPassword"
            :rules="[
              (val) => (val && val.length > 0) || '请输入新密码',
              (val) =>
                val === this.formData.newPassword || '两次新密码不一致，请检查',
            ]"
          >
            <template v-slot:before>
              <div class="text-dark flex items-center text-bold input-text justify-end">
                确认新密码：
              </div>
            </template>
            <template v-slot:append>
              <q-btn
                round
                dense
                flat
                :icon="iconState.confirmNewPassword"
                @mousedown="iconState.confirmNewPassword = 'visibility_off'"
                @mouseup="iconState.confirmNewPassword = 'visibility'"
              ></q-btn>
            </template>
          </q-input>
          <div class="btn-box">
            <q-btn
              unelevated
              rounded
              class="submitBtn"
              type="submit"
            >
              <span>提交</span>
            </q-btn>
          </div>
        </q-form>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { changepassword } from "@/api/personal";
export default {
  components: {},

  data() {
    return {
      show: false,
      formData: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      iconState: {
        oldPassword: "visibility",
        newPassword: "visibility",
        confirmNewPassword: "visibility",
      },
    };
  },

  props: {
    remove: {
      type: Function,
      default: null
    }
  },

  watch: {
    show(n) {
      if (!n) {
        if (this.remove) this.remove();
      }
    }
  },

  mounted() {},

  methods: {
    open() {
      this.show = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    onSubmit() {
      changepassword(this.formData).then((r) => {
        this.$message.success("修改成功");
        this.show = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  #update-password {
    .content {
      width: 500px;
      max-width: none;
      box-shadow: none;
      overflow: hidden;
    
      .header {
        width: 100%;
        height: 90px;
        position: relative;
        border-radius: 60% 60% 0 0;
        background-image: linear-gradient(90deg, #7d1edc 0%, #12c2e9 100%);
        .title {
          font-size: 36px;
          color: #ffffff;
        }
        .close {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          position: absolute;
          right: 27px;
          bottom: 15px;
          cursor: pointer;
          border: 2px solid white;
          transition: all 0.5s;

          &:hover {
            transform: rotate(90deg) scale(1.08);
          }
          .closeIcon {

            &::after,
            &::before {
              content: "";
              position: absolute;
              left: 15px;
              height: 20px;
              width: 1.5px;
              top: 6px;
              background-color: #fff;
            }

            &::before {
              transform: rotate(45deg);
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 138px);
        max-height: 336px;
        border-radius: 0 0 20px 20px;
        background: #fff;
        padding: 20px;
        overflow: auto;

        .q-form {

          .input-text {
            width: 100px;
            height: 100%;
            font-size: 15px;
          }
          .q-field--with-bottom {
            padding-bottom: 32px;
          }
          .q-input {
            margin-top: 10px;
            width: 360px;
          }
          .btn-box {
            margin-top: 10px;

            .submitBtn {
              margin: 0 auto;
              display: block;
              // background-image: linear-gradient(90deg, #12c2e9 0%, #7d1edc 100%);
              font-size: 20px !important;
              color: #fff;
              padding: 3px 60px;
              min-height: 0px;
              background: linear-gradient(
                to right,
                #12c2e9 0%,
                #7d1edc 50%,
                #12c2e9 100%
              );
              background-size: 200% auto;
              transition: 0.5s;

              &:hover {
                background-position: right center;
              }
            }
          }
        }
      }
    }
  }

  :deep(.bg-myWathet) {
    background: #e5f2ff !important;
  }
</style>
