<template>
  <q-field
    dense
    outlined
    :value="value3"
    :rules="rules"
    @click.native="onClick"
    @clear="onClear"
    :clearable="clearable && (value3 !== defaultShow)"
  >
    <template v-slot:prepend>
      <q-icon name="event" style="color: #1EB0FC;">
        <q-popup-proxy ref="qDateProxy">
          <q-date
            v-model="value2"
            :locale="myLocale"
            range
            @range-end="onRangeEnd"
          >
            <div class="items-center justify-end">
              <q-btn v-close-popup label="关闭" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
    <template v-slot:control>
      <div class="full-width text-center">{{ value3 }}</div>
    </template>
  </q-field>
</template>
<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: ""
    },
    defaultShow: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      defalut() {
        return null;
      }
    },
  },
  data() {
    return {
      value2: "",
      myLocale: {
        days: "周日_周一_周二_周三_周四_周五_周六".split("_"),
        daysShort: "周日_周一_周二_周三_周四_周五_周六".split("_"),
        months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ),
        monthsShort: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ),
        firstDayOfWeek: 1
      }
    };
  },
  computed: {
    value3() {
      const { value2 } = this;
      if (value2) {
        const t = typeof value2;
        if (t == "string") {
          return value2;
        } else {
          const { from, to } = this.value2;
          if (from || to) {
            if (from == to) {
              return `${from}`;
            } else {
              return `${from} 至 ${to}`;
            }
          } else return this.defaultShow;
        }
      } else {
        return this.defaultShow;
      }
    }
  },
  watch: {
    value: {
      handler(n) {
        this.value2 = n;
      },
      immediate: true,
      deep: true
    },
    value2: {
      handler(n) {
        if (typeof n == "string") {
          this.$emit("input", { from: n, to: n });
        } else this.$emit("input", n);
      },
      deep: true
    }
  },
  methods: {
    onClick() {
      this.$refs.qDateProxy.show();
    },
    onClear() {
      this.value2 = null;
    },
    onRangeEnd() {
      this.$refs.qDateProxy.hide();
    }
  }
};
</script>
<style lang="scss" scoped>
  .q-field {
    min-width: 265px;
  }
  // 关闭日期控件的头部
  .q-date {
    :deep(.q-date__header) {
      display: none;
    }
  }
</style>
