<template>
  <q-field
    dense
    outlined
    :value="value2"
    :rules="rules"
    @clear="onClear"
    :clearable="clearable"
  >
    <template v-slot:prepend v-if="type !== 'time'">
      <q-icon name="event" style="color: #1EB0FC;">
        <q-popup-proxy ref="qDateProxy">
          <q-date
            v-model="value2"
            @update:model-value="selectDate"
            :locale="myLocale"
            :mask="type === 'dateTime' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'"
          >
            <div class="items-center justify-end">
              <q-btn v-close-popup label="关闭" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
    <template v-slot:control>
      <div class="full-width text-center">{{ value2 }}</div>
    </template>
    <template v-slot:append v-if="type !== 'date'">
      <q-icon name="access_time" style="color: #1EB0FC;">
        <q-popup-proxy ref="qTimeProxy">
          <q-time
            v-model="value2"
            @update:model-value="selectTime"
            :locale="myLocale"
            :mask="type === 'dateTime' ? 'YYYY-MM-DD HH:mm' : 'HH:mm'"
            format24h
          >
            <div class="items-center justify-end">
              <q-btn v-close-popup label="关闭" color="primary" flat />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-field>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    type: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      defalut() {
        return null;
      }
    }
  },
  data() {
    return {
      value2: "",
      myLocale: {
        days: "周日_周一_周二_周三_周四_周五_周六".split("_"),
        daysShort: "周日_周一_周二_周三_周四_周五_周六".split("_"),
        months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ),
        monthsShort: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ),
        firstDayOfWeek: 1
      }
    };
  },
  watch: {
    modelValue: {
      handler(n) {
        this.value2 = n;
      },
      immediate: true,
      deep: true
    },
    value2: {
      handler(n) {
        this.$emit("update:model-value", n);
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.value2 = null;
    },
    selectDate() {
      this.$refs.qDateProxy.hide();
    },
    selectTime() {
      this.$refs.qTimeProxy.hide();
    }
  }
};
</script>
<style lang="scss" scoped>
  .q-field {
    min-width: 200px;
  }
  // 关闭日期控件的头部
  .q-date {
    ::v-deep .q-date__header {
      display: none;
    }
  }
  .q-time {
    ::v-deep .q-time__header {
      display: none;
    }
  }
</style>
