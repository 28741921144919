// import microApp from "@micro-zoe/micro-app";
// import Vue from "vue";
import { getSubsiteUrl } from '@/utils/wujie'

import message from '@/utils/message'
// import TrainDialog from "@/components/TrainDialog/index.js";

import BDialog from '../BstiComponents/BDialog' 
import BDataSelector from '../BstiComponents/BDataSelector'
import BDatePicker from '../BstiComponents/BDatePicker.vue';
import BDateTime from '../BstiComponents/BDateTime.vue';
// import BFileUploader from '../BstiComponents/BFileUploader'
// import BImageUploader from '../BstiComponents/BImageUploader'

import { Login, SetNewPassword, UpdatePassword } from '../components/loginProcess/index.js'

import richTextDialog from "../components/richTextDialog/index.vue";
import richTextPopup from "../components/richTextDialog/index.js";

import viewPdfDialog from "../components/viewPDF/index.vue";

import bstiplugin from 'bstiplugin-vue'

import { fileFormat, thumbnailFormat, docFileFormat, documentFormat } from "@/utils/format";
 
export default  {
  install: (app) => {
    app.config.globalProperties.$message = message

    if (app.config.globalProperties.$q) {
      app.config.globalProperties.$q.Login = Login.install;
      app.config.globalProperties.$q.UpdatePassword = UpdatePassword.install;
      app.config.globalProperties.$q.SetNewPassword = SetNewPassword.install;
      app.config.globalProperties.$q.EditRichText = richTextPopup;

      app.config.globalProperties.$q.dialog = BDialog.install

      app.config.globalProperties.$q.dataSelect = BDataSelector.install
    }
    app.component("RichTextDialog", richTextDialog);
    app.component("viewPdfDialog", viewPdfDialog);
    app.component("BDatePicker", BDatePicker);
    app.component("BDateTime", BDateTime);
    
    // Vue.component(BFileUploader.name, BFileUploader)
    // Vue.component(BImageUploader.name, BImageUploader)
    // Vue.component('wujie', WujieVue)

    app.config.globalProperties.$getSubsiteUrl = getSubsiteUrl

    // Vue.use(BstiPlugin)
    app.config.globalProperties.$dictReadyAsync = () => {
      return bstiplugin.getApiAsync('base-app', 'dictReadyAsync')
    }

    app.config.globalProperties.$dicts = (key) => {
      return bstiplugin
        .getApi(
          'base-app',
          'dicts'
        )(key)
        .map((k) => {
          return { name: k.text, value: Number(k.value) }
        })
    }

    app.config.globalProperties.$dictText = (key, val) => {
      return bstiplugin.getApi('base-app', 'dictText')(key, val)
    }

    app.config.globalProperties.$fileFormat = (fileKey) => {
      return fileFormat(fileKey)
    }

    app.config.globalProperties.$thumbnailFormat = (size, fileKey) => {
      return thumbnailFormat(size, fileKey)
    }

    app.config.globalProperties.$docFileFormat = (fileKey, type) => {
      return docFileFormat(fileKey, type)
    }

    app.config.globalProperties.$documentFormat = (fileKey) => {
      return documentFormat(fileKey)
    }
  }
}
