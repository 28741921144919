// import Vue from "vue";
import {createRouter,createWebHistory, createWebHashHistory} from "vue-router";

import routes from "./routes";

// // 重写路由的push方法
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// // 重写路由的replace方法
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace(location) {
//   return originalReplace.call(this, location).catch((err) => err);
// };
// Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

// const createRouter = () =>
//   new VueRouter({
//     scrollBehavior: () => ({ x: 0, y: 0 }),
//     routes,

//     mode: process.env.VUE_ROUTER_MODE,
//     base: process.env.BASE_URL,
//   });

const router = createRouter({
  history:createWebHistory(),
  routes
});

export default router;
