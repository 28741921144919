<template>
  <div
    v-if="!hidden"
    class="BForm-item"
    :class="{ rules: field.rules && field.rules.length > 0 }"
  >
    <span class="BForm-item-label" :style="{ width: labelWidth }">
      {{field.label}}
      </span>
    <div class="BForm-item-content" :style="{ width: field.width || '300px' }">
      <b-select
        class="select"
        v-if="field.type == 'select'"
        v-model="formData[field.name]"
        :rules="field.rules"
        :remoteAPI="field.remoteAPI"
        :options="field.options"
        :optionLabel="field.optionLabel"
        :optionValue="field.optionValue"
        :clearable="field.clearable"
        :disable="field.disable"
        :changed="field.changed"
        :formData="formData"
        :multiple="field.multiple"
        :multipleKey="field.multipleKey"
      >
      </b-select>

      <b-radio
        v-else-if="field.type == 'radio'"
        v-model="formData[field.name]"
        :rules="field.rules"
        :options="field.options"
        :optionLabel="field.optionLabel"
        :optionValue="field.optionValue"
        :clearable="field.clearable"
        :disable="field.disable"
        :changed="field.changed"
        :formData="formData"
      >
      </b-radio>

      <q-toggle
        v-else-if="field.type == 'toggle'"
        v-model="formData[field.name]"
        :false-value="field.options[0].value"
        :true-value="field.options[1].value"
        color="primary"
        keep-color
        :label="
          formData[field.name] == field.options[0].value
            ? field.options[0].label
            : field.options[1].label
        "
        label-color="white"
      ></q-toggle>

      <b-date-time
        v-else-if="
          field.type == 'dateTime' ||
          field.type == 'date' ||
          field.type == 'time'
        "
        :type="field.type"
        v-model="formData[field.name]"
        :rules="field.rules"
        :clearable="field.clearable"
      ></b-date-time>

      <b-input-number
        v-else-if="field.type == 'inputNumber'"
        v-model="formData[field.name]"
        :min="field.min"
        :max="field.max"
        :step="field.step"
      ></b-input-number>

      <p class="text" v-else-if="field.type == 'text'">
        <span>{{ field.value }}</span>
      </p>

      <div v-else-if="field.type == 'img'" ref="img"></div>

      <q-input
        v-else
        class="input"
        dense
        outlined
        bg-color="50Blue"
        v-model="formData[field.name]"
        autofocus
        :rules="field.rules"
        :type="field.type || 'input'"
        :clearable="field.clearable"
        :counter="field.counter"
        :maxlength="field.maxlength"
      >
      </q-input>
    </div>
    <div class="BForm-item-tip" v-if="field.tip">
      <q-icon name="error_outline" style="color: #1eb0fc">
        <q-tooltip>
          <p class="tipInfo">{{ field.tip }}</p>
        </q-tooltip>
      </q-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: [Object, String, Number, Array, Boolean],
    formData: Object,
    labelWidth: String,
  },
  data() {
    return {
      value2: "",
    };
  },
  computed: {
    hidden() {
      const { field, formData, value } = this;
      const { hidden } = field;
      if (hidden) {
        const t = typeof hidden;
        if (t == "function") {
          return hidden({ field, value, formData });
        } else return hidden;
      } else return false;
    },
  },
  watch: {
    value: {
      handler(n) {
        this.value2 = n;
      },
      immediate: true,
    },
    value2(n) {
      this.$emit("input", n);
    },
  },
  mounted() {
    if (this.field.type == "img") {
      this.initPlugin();
    }
  },
  methods: {
    initPlugin() {
      this.$plugins.isReady().then(async () => {
        this.$plugins
          .getComponentAsync("fileserver-app", "imageuploader")
          .then((imageuploader) => {
            imageuploader(this.$refs.img, {
              fileKey: this.formData[this.field.name],
              onFinish: (res) => {
                this.formData[this.field.name] = res.fileKey;
              },
            });
          })
          .catch((ex) => {
            console.error(ex);
          });
      });
    },
  },
};
</script>
<style lang="scss">
.bg-50Blue {
  background: #e5f2ff !important;
}
</style>
<style lang="scss" scoped>
.BForm-item {
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  .BForm-item-label {
    display: flex;
    height: 40px;
    margin: 0;
    font-size: 16px;
    color: #222222;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
  }
  .BForm-item-content {
    .q-textarea {
      min-width: 300px;
    }
  }
  .BForm-item-tip {
    font-size: 24px;
    margin-left: 5px;
  }
  &.rules::after {
    content: "*";
    color: red;
    margin-left: 5px;
  }
}
.tipInfo {
  white-space: break-spaces;
}
</style>
