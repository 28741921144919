<template>
  <q-dialog v-model="dialog" maximized id="viewPfdDialog">
    <q-card>
      <q-bar>
        <div>PDF阅读器</div>
        <q-btn dense flat icon="close" class="closeBtn" @click="handleClose">
          <q-tooltip content-class="bg-white text-primary">关闭</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <div class="dialogContent" style="height: calc(100vh - 70px)">
          <iframe width="100%" height="100%" id="pdfIframe"></iframe>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "viewPfdDialog",

  components: {},

  data() {
    return { dialog: false };
  },

  mounted() {},

  methods: {
    open(pdfUrl) {
      this.getBlobFile(pdfUrl);
      this.dialog = true;
    },
    // 将pdf文件转换成字节流（解决pdfjs查看pdf文件跨域问题）
    getBlobFile(pdfUrl) {
      axios({
        url: pdfUrl,
        method: "get",
        responseType: "blob",
      }).then((res) => {
        let filestream = res.data;
        const href = window.URL.createObjectURL(filestream);
        document
          .getElementById("pdfIframe")
          .setAttribute("src", `pdfview/web/viewer.html?file=${href}`);
      });
    },
    handleClose() {
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss">
  #viewPfdDialog {
    .closeBtn {
      z-index: 100;
      position: absolute;
      right: 15px;
    }

    .dialogContent {
      height: calc(100vh - 70px);
    }
  }
</style>
