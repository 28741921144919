// import Vue from "vue";
// import Popup from "./BDialog.vue";
// const PopupBox = Vue.extend(Popup);

// Popup.install = function(data) {
//   const instance = new PopupBox().$mount();

//   document.body.appendChild(instance.$el);

//   //   Vue.nextTick(() => {
//   //     instance.show = true;
//   //     // show 和弹窗组件里的show对应，用于控制显隐
//   //   });
//   return instance.open(data);
// };
// export default Popup;

import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import BDialog from './BDialog.vue'

import message from '@/utils/message';

export default {
  install(options) {
    return new Promise((resolve, reject) => {
      const app = createApp(BDialog, {
        // options,
        close: () => {
          app.unmount()
          document.body.removeChild(divEle)
        },
        /* success: (formData) => {
          resolve(formData)
        }, */
      })

      app.use(Quasar)
      app.config.globalProperties.$message = message;

      const divEle = document.createElement('div')

      document.body.appendChild(divEle)
      const t = app.mount(divEle)

      t.open(options).then((res) => {
        resolve(res)
      })
    })
  },
}
