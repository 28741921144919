<template>
  <q-dialog v-model="show" persistent>
    <q-card class="q-pa-md myDialog">
      <q-toolbar>
        <q-toolbar-title class="dlgTitle">{{ title }}</q-toolbar-title>
        <q-btn
          class="closeBtn"
          icon="close"
          dense
          outline
          rounded
          v-close-popup
        >
          <q-tooltip>关闭</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card-section>
        <q-form ref="BForm" @submit="onSubmit">
          <slot name="dialog-content"></slot>
          <b-dialog-item
            v-for="field in fields"
            :key="field.name"
            :field="field"
            :formData="formData"
            :labelWidth="labelWidth"
            :contentWidth="contentWidth"
            v-model="formData[field.name]"
          >
          </b-dialog-item>

          <div class="full-width q-mt-lg save">
            <q-btn class="myBtn" unelevated label="保存" type="submit" />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import BDialogItem from "./BDialogItem";
/* const defaultButtons = [
  { name: "submit", type: "submit", label: "保存", color: "primary" }
]; */

const initFormData = fields => {
  let data = {};
  for (let d of fields) {
    data[d.name] = "";
  }
  return data;
};

export default {
  name: "BDialog",
  components: {
    BDialogItem
  },
  data() {
    return {
      title: "",
      show: false,
      fields: [{}],
      formData: {},
      // buttons: [...defaultButtons], // 显示的按钮
      successNotify: "", // 成功通知
      labelWidth: "", // 表单项名称标签的宽度
      contentWidth: "" // 表单项内容标签的宽度
    };
  },
  props: {
    close: {
      type: Function,
      default: null
    }
  },

  watch: {
    show(n) {
      if (!n) {
        if (this.close) this.close();
      }
    }
  },
  methods: {
    open({ title, fields, buttons, formData, successNotify, api, labelWidth, contentWidth }) {
      const options = {
        title,
        fields,
        // buttons: buttons || defaultButtons,
        formData: formData || initFormData(fields),
        api,
        successNotify,
        labelWidth: labelWidth || "100px",
        contentWidth: contentWidth || "300px"
      };

      Object.assign(this, options);
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onSubmit() {
      if (this.api) {
        // console.log(this.formData, "this.formData");
        this.api(this.formData).then(res => {
          this.callBack(res);
          if (this.successNotify) this.$message.success(this.successNotify);
          this.show = false;
        });
      } else {
        this.callBack(this.formData);

        if (this.successNotify) this.$message.success(this.successNotify);
        this.show = false;
      }
    },
    callBack(res) {
      if (this.resolve) {
        this.resolve(res);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .myDialog {
    min-width: 400px;
    max-width: 1400px !important;
    max-height: calc(100vh - 40px) !important;
    background: rgb(243, 243, 243) !important;

    .q-toolbar {
      min-height: auto;
      margin-bottom: 20px;

      .dlgTitle {
        font-weight: 700;
      }
      .closeBtn {
        z-index: 100;
        position: absolute;
        right: 15px;
      }
    }

    .save {
      text-align: center;
      .q-btn {
        background-color: #48d16d;
        color: #fff;
        border-radius: 10px;
      }
    }
  }
</style>
