<template>
  <div id="q-app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    // window.pluginGetModuleAsync = function (moduleName) {
    //   return new Promise((resolve, reject) => {
    //     window.$bstiplugin
    //       .getModuleAsync(moduleName)
    //       .then((module) => { 
    //         resolve(module);
    //       })
    //       .catch((ex) => { 
    //         reject(ex);
    //       });
    //   });
    // }; 
    if (sessionStorage.getItem("onlineKey")) {
      this.$store.dispatch("onlineRecord");
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
