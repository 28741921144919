// import Vue from "vue";
import {createStore} from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import persistedState from "vuex-persistedstate";
// import example from './module-example'

// Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    // example
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  // strict: process.env.DEBUGGING.anchor,
  plugins: [persistedState({ storage: window.sessionStorage })]
});

export default store;
