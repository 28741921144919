import axios from "../axios/AxiosConfig";
/**
 * 用户服务接口
 */

// 用户个人信息
export function userInfo() {
  return axios({
    url: "user/detail",
    method: "get",
  });
}

// 用户身份状态
export function userauthentication() {
  return axios({
    url: "user/userauthentication",
    method: "get",
  });
}

// 更新用户在线数据
export function useronline({ onlineKey = "" }) {
  return axios({
    url: "useronline",
    method: "post",
    data: { onlineKey }
  });
}