import { getHost } from "./hostshelper";

export function getSubsiteUrl(key, defaultUrl, urlParams) {
  console.log('---key---',key)
  const cfg = getHostMap(key);
  console.log('---cfg---',cfg)
  if (cfg) {
    let url = "";
    if(cfg.host) return cfg.host;
    
    if (cfg.moduleName) {
      url = cfg.moduleName + "/";
    } else {
      url = defaultUrl + "/";
    }

    if (urlParams) return url + urlParams;
    else return url;
  } else {
    return defaultUrl + "/";
  }

  // var host =
  //   process.env.NODE_ENV === "production" ? getHost(key) : getDevHost(key);

  // var host = getDevHost(key);

  // if (urlParams) return host + urlParams;
  // else return host;
}

export function getHostMap(key) {
  if (window.hostsMaps) return window.hostsMaps[key];
  else return "";
}

/* function getDevHost(key) {
  let hm = "";
  if (window.hostsMaps && window.hostsMaps.length > 0) {
    hm = window.hostsMaps.find((k) => k.name == key);
  }

  if (hm) {
    if (process.env.NODE_ENV === "production") {
      const host = hm.host;
      if (host) return host;
    } else {
      const devHost = hm.devHost;
      if (devHost) {
        return devHost;
      }
    }
  }

  return getHost(key);
} */
