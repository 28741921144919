export function hasRight(moduleView, operate) {
  const rights = sessionStorage.getItem("rights");

  if (!rights) return false;

  const sp = rights.split(",");

  for (var i = 0; i < sp.length; i++) {
    if (moduleView.includes("^")) { // 系统管理权限判断
      if (moduleView.split("^").includes(sp[i].split("-")[0])) return true;
    } else {
      if (doCheckRights(sp[i], moduleView, operate)) return true;
    }
  }

  return false;
}

export function checkRight(rights) {
  if (rights) {
    var r = rights.split("&");
    if (r.length < 1) return false;
    else if (r.length == 1 || !r[1]) return hasRight(r[0], "");
    else return hasRight(r[0], r[1]);
  } else return true;
}

const doCheckRights = (rights, moduleView, operate) => {
  const rsp = rights.split("&");

  if (rsp[0] != moduleView) return false;

  if (!operate) return true;

  if (rsp.length <= 1) return false;

  var ops = rsp[1].split("|");

  for (var i = 0; i < ops.length; i++) {
    if (ops[i] == operate) return true;
  }
  return false;
};
