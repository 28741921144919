// import Vue from "vue";
// import Popup from "./BDataSelector.vue";
// const PopupBox = Vue.extend(Popup);

// Popup.install = function(data) {
//   const instance = new PopupBox().$mount();

//   document.body.appendChild(instance.$el);

//   //   Vue.nextTick(() => {
//   //     instance.show = true;
//   //     // show 和弹窗组件里的show对应，用于控制显隐
//   //   }); 
//   return instance.open(data);
// };
// export default Popup;
const err ={}
export default err;
