
import axios from "../axios/AxiosWXConfig";


const appid = 'wxf199f4d4b19ce5e1';
export function loginqrticket(appid) {
    return axios({
        url: `sub-third/api/mpwechatauthor/getloginqrticket/${appid}`,
        method: "get",
    })
}

export function checkqrLoginResult (ticket) {
    return axios({
        url: `sub-third/api/mpwechatauthor/checkqrloginresult/${appid}/${ticket}`,
        method: "get",
    })
}

export function loginbywxcode(code) {
    return axios({
        url: `sub-third/api/mpwechatauthor/loginbycode/${appid}`,
        method: "post",
        data: {code}
    })
}



//https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect