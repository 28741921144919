import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options"; 

import store from '@/store'
import router from '@/router'

import WujieVue from "wujie-vue3";

import "@/styles/app.scss";
import "@/styles/global.scss";

import bsti from '@/boot/bsti'
import rights from '@/boot/rights'
import bstiplugin from '@/boot/bstiplugin'

// const {setupApp} = WujieVue

/* setupApp({
    name: "react16",
    url: '//localhost:7801', 
    exec: true,
    // props,
    // fetch: credentialsFetch,
    // plugins,
    // prefix: { "prefix-dialog": "/dialog", "prefix-location": "/location" },
    // degrade,
    // ...lifecycles,
  }); */

const app = createApp(App)

app.use(Quasar, quasarUserOptions)
.use(router).use(store)
.use(bsti).use(rights).use(bstiplugin)
.use(WujieVue).mount("#app");

export const globals = app.config.globalProperties;