import Axios from "axios";
import { globals } from "@/main.js";
import Notify from 'quasar/src/plugins/Notify.js';;
import qs from "qs";
import _config from "@/config";
import router from "@/router";
/**
 * axios initialization
 */

const baseURL =`${_config.host}/${_config.apiRoute}` 

const axios = Axios.create({
  baseURL,  // _config.host,
  // timeout: globals.$timeOut,
});

let curRequestURL;

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    // 记录当前请求URL
    curRequestURL = config.url;

    const token = sessionStorage.getItem("access_token");
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJ1dWlkIjoiYWFlYTAxZmU4MGVkNGJhZjhiYTE1ODI1NDYxMGE5MGQiLCJuYW1lIjoiYWRtaW4iLCJyb2xlIjoiYWRtaW4iLCJuYmYiOjE2MzIzNjg0MjIsImV4cCI6MTYzMjk3MzIyMiwiaWF0IjoxNjMyMzY4NDIyLCJpc3MiOiJCc3RpU2VydmVyIiwiYXVkIjoiYXBpIn0.c9av8FrciMDE0nqCac0wCzKMww8xvWFXzm9lWzMRCQc'
    config.headers.Authorization = "Bearer " + token;
    if (config.type) {
      switch (config.type) {
        case "FORM-DATA":
          config.transformRequest = [
            (data) => {
              return "args=" + JSON.stringify(data);
            },
          ];
          break;
        case "FORM":
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
          config.data = qs.stringify(config.data);
          break;
        default:
          break;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const defaultNotify = {
  message: "未知错误",
  icon: "warning",
  color: "warning",
  position: "top",
  timeout: 1500,
};

axios.interceptors.response.use(
  (response) => {
    if (response.config.download) {
      downloadByBlob(response);
    } else {
      if (response.data.succeeded) {
        return response.data.data;
      } else {
        let reg = /useronline/;
        if (!reg.test(curRequestURL) || !(response.data.errors === "参数错误，本次数据无效")) {
          defaultNotify.message = typeof response.data.errors === "string" ? response.data.errors : "数据异常";
          Notify.create(defaultNotify);
        }
        return Promise.reject(response.data);
      }
    }
  },
  (error) => {
    if (
      error.code === "ECONNABORTED" ||
      error.message.indexOf("timeout") !== -1 ||
      error.message === "Network Error"
    ) {
      defaultNotify.message = "网络异常";
      router.push({ name: "login" });
      Notify.create(defaultNotify);
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 401:
        defaultNotify.message = "未授权，请重新登录";
        Notify.create(defaultNotify);
        router.push({ name: "login" });
        break;
      case 403:
        defaultNotify.message = "拒绝访问(403)";
        Notify.create(defaultNotify);
        break;
      case 404:
        defaultNotify.message = "资源不存在(404)";
        Notify.create(defaultNotify);
        break;
      case 408:
        defaultNotify.message = "请求超时(408)";
        Notify.create(defaultNotify);
        break;
      case 500:
        defaultNotify.message = "服务器错误(500)";
        Notify.create(defaultNotify);
        break;
      case 501:
        defaultNotify.message = "服务未实现(501)";
        Notify.create(defaultNotify);
        break;
      case 502:
        defaultNotify.message = "网络错误(502)";
        Notify.create(defaultNotify);
        break;
      case 503:
        defaultNotify.message = "服务不可用(503)";
        Notify.create(defaultNotify);
        break;
      case 504:
        defaultNotify.message = "网络超时(504)";
        Notify.create(defaultNotify);
        break;
      case 505:
        defaultNotify.message = "HTTP版本不受支持(505)";
        Notify.create(defaultNotify);
        break;
      default:
        Notify.create(defaultNotify);
        break;
    }
    return Promise.reject(error);
  }
);

export default axios;

export function downloadByBlob(res) {
  const { data, headers } = res;
  const fileType = headers["content-type"]; // 文件类型
  const fileName = getFileName(headers["content-disposition"]);
  const url = window.URL.createObjectURL(new Blob([data], { type: fileType }));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getFileName(contentType) {
  try {
    const filenameRegex1 = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matchs1 = filenameRegex1.exec(contentType);
    if (matchs1.length > 1) {
      // 处理utf8
      return decodeURI(matchs1[1].replace("UTF-8''", ""));
    } else {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

      const matchs = filenameRegex.exec(headers["content-disposition"]);
      if (matchs.length > 1) {
        return decodeURI(matchs[1]);
      }
    }
  } catch {}
  return "";
}
