import axios from "../axios/AxiosConfig";
//import JSEncrypt from "jsencrypt";
/**
 * 个人信息接口
 */

const pubKey =
  "-----BEGIN PUBLIC KEY-----\r\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCw0JszFLtD+LTUmfaociVGDaTM\r\nos/6X0mo8lG7YETTXDlmWB6dxuEMT0VqVQxIDdws4Tej06pvXSWRgqObuyPfH2bK\r\nC1oK8sgxPKgwXy7esBLoo6UMEUVBYRDpb8dfnJORqM63l8hZyaB4Va4hPZCUVxIc\r\n4L9A9qF3+/gAlg943wIDAQAB\r\n-----END PUBLIC KEY-----\r\n";

// 个人信息
export function personalInfo() {
  return axios({
    url: "personal",
    method: "get",
  });
}

// 修改密码
export function changepassword({
  oldPassword,
  newPassword
}) {
  var encryptor = new JSEncrypt(); // 创建加密对象实例
  encryptor.setPublicKey(pubKey); // 设置公钥
  var rsaOldPassWord = encryptor.encrypt(oldPassword); // 对旧密码进行加密
  var rsaNewPassWord = encryptor.encrypt(newPassword); // 对新密码进行加密

  return axios({
    url: "personal/changepassword",
    method: "post",
    data: { oldPassword: rsaOldPassWord, newPassword: rsaNewPassWord, security: true },
  });
}
