const mutations = {
  /**
   * sign out
   * @param state
   * @param payload
   * @constructor
   */
  LOGOUT: (state, payload) => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("rights")
    sessionStorage.removeItem("personal");
    sessionStorage.removeItem("onlineKey");
    sessionStorage.removeItem("uuid");

    clearTimeout(state.onlineTimer);
    state.onlineTimer = null;
  },
  set_onlineTimer(state, payload) {
    state.onlineTimer = payload;
  }
};

export default mutations;
