<template>
  <q-dialog v-model="show">
    <q-card class="q-pa-md richTextDialog">
      <q-toolbar>
        <q-toolbar-title class="text-h6 title">{{ title }}</q-toolbar-title>
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          outline
        >
          <q-tooltip>关闭</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card-section>
        <q-form @submit="onSubmit">
          <div ref="ckeditor"></div>
          <div class="full-width q-mt-lg" align="center" style="text-align: center;">
            <q-btn
              unelevated
              style="background: #296dd6; color: #fff;"
              label="保存"
              type="submit"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      content2: this.content // 收集ckeditor中编辑的内容
    }
  },
  props: {
    title: {
      type: String,
      default: "编辑富文本"
    },
    content: String,
    close: {
      type: Function,
      default: null
    },
    success: {
      type: Function,
      default: null
    }
  },

  watch: {
    show(n) {
      if (!n) {
        if (this.close) this.close();
      }
    }
  },
  mounted() {
    // 初始化ckeditor
    window.$bstiplugin.getComponentAsync("ckeditor-app", "ckeditor").then((ckeditor) => {
      ckeditor(this.$refs.ckeditor, {
        content: this.content,
        onChange: (res) => {
          this.content2 = res;
        },
      });
    }).catch((ex) => {
      console.error(ex);
    });

    this.show = true;
  },
  methods: {
    onSubmit() {
      if (this.success) this.success(this.content2);
      this.show = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 自定义对话框 */
  .richTextDialog {
    min-width: 900px;
    max-width: 1400px !important;
    background: #fff !important;
    overflow-x: hidden;
    overflow-y: auto;

    :deep(.ck-editor__editable) {
      min-height: 350px;
    }
  }
</style>
