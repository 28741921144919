import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import richTextDialog from './index.vue'

export default function richTextPopup(options = {}) {
  const app = createApp(richTextDialog, {
    ...options,
    close: () => {
      app.unmount()
      document.getElementById("app").removeChild(divEle)
    }
  })

  app.use(Quasar)

  const divEle = document.createElement('div')

  document.getElementById("app").appendChild(divEle)
  return app.mount(divEle)
}