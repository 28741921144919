import bstiplugin from "bstiplugin-vue";
import config from "@/config";

const getToken = () => {
  const token = sessionStorage.getItem("access_token");
  return token;
};

const modules = [
  "base-app",
  "fileserver-app",
  "exam-sys",
  "exam-app",
  "statistics-app",
  "library-app",
  "bsti3d",
  "libcourse-app",
  "ckeditor-app",
  "simulation-app",
  "plan-app",
  "compete-app",
];

const host = config.pluginHost; //"http://192.168.10.242:8280/plugins";
 
bstiplugin
  .config({
    host,
    modules,
  })
  .load((module, md) => {
    if (md && md.init) md.init(bstiplugin, { getToken });
  });

window.$bstiplugin = bstiplugin;

export default{
  install: (app) => {
    app.config.globalProperties.$plugins = bstiplugin;
  }
}

