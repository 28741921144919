export default {
  get host() {
    return process.env.VUE_APP_BASE_API
  },
  get apiRoute() { 
    return process.env.VUE_APP_BASE_API_ROUTE || 'api'
  },
  get pluginHost() {
    return process.env.VUE_APP_PLUGIN_HOST || 'plugins'
  },
  get fileHost() {
    return process.env.VUE_APP_FILE_ROOT
  },
}
